@use "sass:map";
@import '_theme';
@import '_responsive';
html,
body {
    margin: 0;
}
* {
    font-family: Roboto;
    padding: 0;
    margin: 0;
}
.container {
    max-width: 1170px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    // background: map.get($theme-colors, 'main');
    margin: auto;
}
.row {
    display: flex;
    flex-wrap: wrap;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
}
.col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
}
.col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.button-submit {
    font-size: 14px;
    color: #ffffff;
    font-weight: 800;
    text-transform: uppercase;
    display: inline-block;
    padding: 16px 30px 17px;
    background: map.get($theme-colors,"main");
    border: none;
    height: 100%;

}

@include Responsive($DESKTOP) {
    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@include Responsive($MEDIUM_DEVICE) {
    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
       
    }
}

@include Responsive($TABLET_DEVICE) {
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
       
    }
    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
       
    }
}

@include Responsive($WIDE_MOBILE) {
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
       
    }
    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
       
    }
}
@include Responsive($SMALL_DEVICE) {
    .col-xs-12 {
        flex: 0 0 100%;
        max-width: 100%;
       
    }

}