@use "sass:map";
@import 'styles/pages/_theme';

$bannerImg:'/assets/users/images/hero/banner.jpg';

.header__top {
    background: #f5f5f5 ;
}

.header__top_left {
    ul {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 10px 0 13px;
        column-gap: 20px;

        li {
            font-size: 14px;
            color: map.get($theme-colors, 'normal-text');
            display: flex;
            align-items: center;
            column-gap: 2px;
        }

        li:first-child {
            position: relative;

            &:after {
                position: absolute;
                right: -10px;
                top: 1px;
                height: 20px;
                width: 1px;
                background:#000000;
                opacity: 0.1;
                content: "";

            }
        }

    }
    
}

.header__top_right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 0 13px;

    ul {
        display: flex;
        column-gap: 5px;
        list-style: none;

        a {
            font-size: 14px;
            display: inline-block;
            color: map.get($theme-colors, 'normal-text');
            text-decoration: none;
        }
        li:last-child {
            cursor: pointer;
            margin-left: 10px;
           
            span {
                font-size: 14px;
                margin-left: 2px;
                
            }
        }
    }
}

.header__logo {
    padding: 15px 0;
}
.header__menu {
    padding: 24px 0;

    ul {
        display: flex;
        justify-content: space-between;

        li {
            list-style: none;
            position: relative;

            a {
                text-decoration: none;
                font-size: 14px;
                color: map.get($theme-colors, 'bold-text');
                text-transform: uppercase;
                font-weight: bold;
                letter-spacing: 2px;
                transition: all 0.3s;
                padding: 5px 0;
                display: block;
            }
        }
        li:hover .header__menu__dropdown {
            opacity: 1;
            visibility: visible;
        }

        .active a {
            color: map.get($theme-colors, 'main');
        }

        .header__menu__dropdown {
            background: #222222;
            display: block;
            position: absolute;
            width: 180px;
            z-index: 9;
            padding: 5px 0;
            transition: all 0.3s;
            opacity: 0;
            visibility: hidden;

            li {
                a {
                    text-transform: capitalize;
                    color: white;
                    font-weight: 500;
                    padding: 5px 15px;
                }
                :hover {
                    color: map.get($theme-colors, 'main');
                }
            }
        }
    }
}

.header__cart {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 0;

    ul {
        display: flex;
        margin-right: 25px;

        li {
            list-style: none;
            position: relative;
            margin-left: 20px;

            svg {
                font-size: 25px;
                color: map.get($theme-colors, 'normal-text');
            }
            span {
                color: white;
                background: map.get($theme-colors, 'main');
                position: absolute;
                height: 20px;
                width: 20px;
                border-radius: 50%;
                display: inline-block;
                text-align: center;
                line-height: 20px;
                font-size: 12px;
                font-weight: 600;
                left: 16px;
                top: -6px;
            }
            
        }

    }

}

.hero__categories {
    position: relative;
    margin-bottom: 20px;

    .hero__categories__all{
        background: map.get($theme-colors, 'main');
        padding: 15px 25px 15px 40px;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #ffffff;
        font-weight: 700;
    
        svg,
        span {
            font-size: 16px;
            margin-right: 10px;
        }
        svg {
            font-size: 22px;
            font-weight: bold;
        }
    }

    ul {
        position: absolute;
        border: 1px solid #ebebeb;
        width: 99%;
        background:#FFFFFf;
        transition: all 0.2s;

        li{
            list-style: none;

            a {
                text-decoration: none;
                background-color: transparent;
                font-size: 16px;
                color: map.get($theme-colors, 'normal-text');
                line-height: 39px;
                display: block;
                padding-left: 40px;
            }
            
            &:hover {
                background: #f7ffffff;

                a {
                    color: map.get($theme-colors, 'main');
                }
            }
        }

        &.hidden {
            opacity: 0;
        }
    }
}
.hero__search_container {
    
    .hero__search__form {
        padding-left: 15px;
        width: 620px;
        height: 50px;
        float: left;
        margin-bottom: 30px;

        form {
            display: flex;
            align-items: center;
        }
        input {
            border: none;
            height: 48px;
            font-size: 16px;
            color: #b2b2b2;
            padding-left: 20px;
            border: 1px solid #ebebeb;
            width: 70%;
            
            :focus {
                outline: none;
                border: none;
            }
        }
        button {
            size: 14px;
            color: #ffffff;
            font-weight: 800;
            text-transform: uppercase;
            display: inline-block;
            padding: 16px 30px 17px;
            background: map.get($theme-colors, 'main');
            border: none;
            height: 100%;
        }
    }

    .hero__search__phone {
        display: flex;
        align-items: center;

        .hero__search__phone__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f5f5f5f5;
            padding: 10px;
            line-height: 50px;
            text-align: center;
            font-size: 25px;
            border-radius: 50%;
            float: left;
            margin-right: 20px;
            color: map.get($theme-colors, 'main');
        }
        .hero__search__phone__text {
            display: inline-block;

            p {
                color: map.get($theme-colors, 'normal-text');
                font-weight: bold;
                margin-bottom: 5px;
            }

            span {
                color: #6f6f6f6f;
                font-size: 14px;
            }
            
        }
    }

    .hero__item {
        margin-left: 15px;
        background-image: url($bannerImg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 410px;
        display: flex;
        align-items: center;
        width: 100%;
        margin-left: 15px;

        .hero__text {
            padding-left: 75px;

            span {
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 700;
                letter-spacing: 4px;
                color: map.get($theme-colors, 'main');
            }
            h2 {
                font-size: 45px;
                text-transform: uppercase;
                font-weight: 700;
                line-height: 52px;
                color: map.get($theme-colors, 'bold-text');
                margin: 10px 0;
            }
            p {
                font-size: 16px;
                color: #6f6f6f6f;
                font-weight: 400;
                line-height: 26px;
                margin: 0 0 15px 0;
            }
            a {
                display: inline-block;
                font-size: 14px;
                padding: 10px 28px 10px;
                color: #ffffff;
                text-transform: uppercase;
                font-weight: 700;
                background: map.get($theme-colors, 'main');
                letter-spacing: 2px;
                text-decoration: none;
            }
        }
    }
    @media only screen and (max-width: 768px) {
        .hero__search {
            display: flex;
            flex-wrap: wrap;
        
        }

        .hero__search__form{
            padding: 0;
        }
        
        .hero__search__form button {
            width: 25%;
        }

        .hero__item {
            flex-direction: column;
            height: auto;

            .hero__text {
                padding: 0;
                text-align: center;

                h2 {
                    font-size: 36px;
                    line-height: 42px;
                }
            }
        }
    }

}
