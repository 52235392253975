@use "sass:map";
@import 'styles/pages/_theme';

.contaner__categories_slider {
    max-width: 1200px !important;
    margin-top: 30px !important;

    .categories_slider {

        .categories_slider_item {
            height: 300px;
            position: relative;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top center;
            border-radius: 5px;

            p {
                position: absolute;
                left: 5%;
                bottom: 20px;
                text-align: center;
                font-size: 18px;
                color: map.get($theme-colors, 'normal-text');
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 2px;
                padding: 12px 0 10px;
                width: 270px;
                cursor: pointer;

                &:hover {
                    background: rgb(237 237 237);
                }
            }
        }
        .react-multi-carousel-item:not(:first-child):not(:last-child) {
            margin-left: 10px;
            margin-right: 10px;
        }
    }
}
.featured {
    margin-top: 50px;

    .section-title {
        margin-bottom: 50px;
        text-align: center;

        h2 {
            position: relative;
        }
        h2:after {
            position: absolute;
            left: 0;
            bottom: -15px;
            right: 0;
            height: 4px;
            width: 80px;
            background: map.get($theme-colors, 'main');
            content: '';
            margin: 0 auto;
        }
    }

    .react-tabs {
        
        ul {
            display: flex;
            justify-content: center;
            margin-bottom: 50px;
        }
        li{
            list-style: none;
            font-size: 18px;
            color: map.get($theme-colors, 'normal-text');
            display: inline-block;
            margin-right: 25px;
            position: relative;
            cursor: pointer;
        }
    }

    .featured__item {
        margin-bottom: 50px;
        margin-right: 30px;

        &:hover {
            .featured__item__pic__hover {
                bottom: -15%;
            }

        }

        .featured__item__pic {
            height: 270px;
            position: relative;
            overflow: hidden;
            background-position: center;

            ul {
                position: absolute;
                left: 0;
                bottom: -40%;
                width: 100%;
                text-align: center;
                transition: all 0.5s;

                li {
                    list-style: none;
                    display: inline-block;
                    margin-right: 10px;
                    font-size: 25px;

                    &:hover {
                        svg {
                            background: #7fad39;
                            border-color: #7fad39;
                            color: #ffffff;
                            border-radius: 50%;
                            transform: rotate(360deg);
                        }
                    }
                    svg {
                        padding: 10px;
                        height: 20px;
                        width: 20px;
                        border: 1px solid #ebebeb;
                        background:#ffffff;
                        color: #56555555;
                        transition: all 0.3s;
                    }
                }
            }

        }

        .featured__item__text {
            text-align: center;

            h6 {
                margin-top: 10px;
                font-size: 16px;
                font-weight: 500;

                a {
                    color: map.get($theme-colors, 'normal-text');
                    text-decoration: none;
                }
            }

            h5 {
                margin-top: 10px;
                font-size: 18px;
                font-weight: 800;
                color: map.get($theme-colors, 'normal-text');
            }
        }
    }
  
}
.banner {
    display: flex;
    margin-bottom: 30px;
    gap: 20px;

    img {
        width: 99%;
    }
}
